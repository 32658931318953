import * as React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';

export default function Loading(props) {

    const [carregando, setCarregando] = React.useState(props.carregando);

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={carregando}
            onClick={() => { setCarregando(false) }}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}