import * as React from 'react';
import { Box, Tooltip, Button, MenuItem, TextField, Container, Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { toast } from 'react-toastify';

import PageDefault from '../../components/PageDefault';
import api from '../../services/api';
import { streets } from '../../services/helper';
import { Casino, Home, HomeWork, Store } from '@mui/icons-material';

export default function ListImoveis() {
  const [imoveis, setImoveis] = React.useState([]);
  const [ruaForm, setRuaForm] = React.useState('todas');
  const [selecionados, setSelecionados] = React.useState([]);
  const [carregando, setCarregando] = React.useState(false);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 20,
  });
  const [totalElements, setTotalElements] = React.useState(0);

  const navigate = useNavigate();

  const { contribuinteID } = useParams();

  const columns = [
    { field: 'id', headerName: 'ID', width: 50 },
    { field: 'endereco', headerName: 'Endereço', width: 400 },
    { field: 'complemento', headerName: 'Complemento', width: 120 },
    { field: 'contribuinte', headerName: 'Contribuinte', width: 250 },
    { field: 'natureza', headerName: 'Natureza', width: 70, renderCell: (param) => {
      return param.value === 'Construido' ?
      <Tooltip title='Construído'>
        <HomeWork />
      </Tooltip> :
      <Tooltip title='Terreno'>
        <Casino  />
      </Tooltip>
    }},
    { field: 'tipo', headerName: 'Tipo', width: 50, renderCell: (param) => {
      return param.value === 'Comercial' ?
      <Tooltip title='Comercial'>
        <Store  />
      </Tooltip> :
      <Tooltip title='Residencial'>
        <Home  />
      </Tooltip>
    }},
    { field: 'area', headerName: 'Área', width: 70 },
    { field: 'valorM2', headerName: 'Valor M²', width: 70 },
    { field: 'qtdPavimento', headerName: 'Pisos', width: 60 },
    { field: 'titularUnico', headerName: 'Único Titular', width: 90 },
    { field: 'imovelCTN', headerName: 'Imóvel CTN', width: 90 },
    { field: 'isPlanoDiretor', headerName: 'Plano Diretor', width: 90 }
  ];

  React.useEffect(() => {
    setCarregando(true);

    let query = contribuinteID ? `contribuinteId=${contribuinteID}&` : '';
    query = query.concat(ruaForm !== 'todas' ? `rua=${ruaForm}&` : '');

    api.get(`/contribuinte/imovel?${query}page=${paginationModel.page}&size=${paginationModel.pageSize}`)
      .then(res => {
        const aux = [];
        res.data.content.forEach((item) => {
          const { endereco } = item;
          const numero = endereco.numero === 0 ? 'S/N' : endereco.numero
          aux.push({
            ...item,
            endereco: endereco.rua + ', ' + numero + ', ' + endereco.bairro,
            complemento: endereco.complemento,

            imovelCTN: item.imovelCTN ? 'Sim' : '',
            isPlanoDiretor: item.isPlanoDiretor ? 'Sim' : '',
            titularUnico: item.titularUnico ? 'Sim' : ''
          });
        });
        setImoveis(aux);
        setTotalElements(res.data.totalElements);
        setCarregando(false);
      })
      .catch(err => {
        if (err.response) {
          const message = err.response.data.message;
          toast.error(message);
        }
        setImoveis([]);
        setTotalElements(0);
        setCarregando(false);
      });
  }, [contribuinteID, paginationModel.page, paginationModel.pageSize, ruaForm]);

  return (    
    <PageDefault carregando={false} title='Imóveis'>
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
          <Grid container spacing={3} mt={2}>
              <Grid item xs={12} >
                  <TextField
                      id="rua"
                      select
                      fullWidth
                      required
                      label="Rua"
                      value={ruaForm}
                      onChange={e => {
                          setRuaForm(e.target.value);
                          setPaginationModel({
                              ...paginationModel,
                              page: 0
                          })
                      }}
                      disabled={carregando}
                  >
                    <MenuItem value='todas'>Todas</MenuItem>
                    {streets.map(s => (
                      <MenuItem value={s} key={s}>
                          {s}
                      </MenuItem>    
                    ))}
                  </TextField>
              </Grid>
          </Grid>
      </Container>
      <div className='acoes'>
        <Tooltip title="Adicionar imóvel">
          <span>
            <Button startIcon={<AddIcon />} disabled={ contribuinteID ? false : true } onClick={() => (
              navigate(`/contribuintes/${contribuinteID}/novo-imovel`)
            )}>
              Adicionar
            </Button>
          </span>
        </Tooltip>
        <Tooltip title="Editar imóvel">
          <span>
            <Button startIcon={<EditIcon />} disabled={selecionados.length !== 1} onClick={() => {
              let contribuinte_id = contribuinteID;

              if (!contribuinte_id) {
                imoveis.forEach((item) => {
                  if (selecionados[0] === item.id) {
                    contribuinte_id = item.contribuinte_id;
                  } 
                })
              }
              navigate(`/contribuintes/${contribuinte_id}/editar-imovel/${selecionados[0]}`);
            }}>
              Editar
            </Button>
          </span>
        </Tooltip>
        <Tooltip title="Remover imóveis">
          <span>
            <Button disabled={true} startIcon={<DeleteIcon />}>Remover</Button>
          </span>
        </Tooltip>
        <Tooltip title="Gerar DAM para pagamento">
          <span>
            <Button startIcon={<RequestQuoteIcon />} disabled={selecionados.length !== 1} onClick={() => {
              let contribuinte_id = contribuinteID;

              if (!contribuinte_id) {
                imoveis.forEach((item) => {
                  if (selecionados[0] === item.id) {
                    contribuinte_id = item.contribuinte_id;
                  } 
                })
              }
              navigate(`/contribuintes/${contribuinte_id}/imoveis/${selecionados[0]}/calcular-imposto`);
            }}>
                DAM
            </Button>
          </span>
        </Tooltip>
      </div>
      <Box sx={{ height: 600, width: '100%' }}>
        <DataGrid
          rows={imoveis}
          columns={columns}
          loading={carregando}
          paginationMode='server'
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[paginationModel.pageSize]}
          rowCount={totalElements}
          //keepNonExistentRowsSelected
          checkboxSelection
          // disableRowSelectionOnClick
          onRowSelectionModelChange={(a) => {
            setSelecionados(a)
          }}
          localeText={{
            noRowsLabel: 'Sem imóveis para exibir'
          }}
        />
      </Box>  
    </PageDefault>
  );
}