import * as React from 'react';
import Routes from './Routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { createTheme, ThemeProvider } from '@mui/material/styles';
// import { Alert, Snackbar } from '@mui/material';
// import { clearAlert, getAlert } from './services/helper';

const theme = createTheme();

export default function App() {
  // const [alert, setAlert] = React.useState({
  //   show: false,
  //   type: 'error',
  //   message: ''
  // });

  // const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

  // React.useEffect(() => {
  //   window.addEventListener('storage', handleChangeStorage);

  //   async function handleChangeStorage() {
  //     const { msg, type } = getAlert();
  
  //     if (msg) {
  //       setAlert({
  //         show: true,
  //         type: type,
  //         message: msg
  //       });
  //       await sleep(1000);
  //       clearAlert();
  //     }
  //   }
  // }, []);
  
  return (
    <ThemeProvider theme={theme}>
      <ToastContainer 
        position='bottom-left'
      />
      {/* <Snackbar open={alert.show} autoHideDuration={6000} onClose={() => setAlert({...alert, show: false})}>
        <Alert onClose={() => setAlert({...alert, show: false})} severity={alert.type} sx={{ width: '100%' }}>
          {alert.message}
        </Alert>
      </Snackbar> */}
      <Routes />
    </ThemeProvider>
  );
}