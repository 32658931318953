import axios from 'axios';
import { getToken } from './auth';
import { toast } from 'react-toastify';

const apiDAM = axios.create({
    baseURL: process.env.REACT_APP_URI_API_DAM,
    headers: {'Content-Type': 'application/json'}
});

apiDAM.interceptors.request.use(function (config) {
    const token = getToken();
    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }
    return config;
})

apiDAM.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    if (error.response) {
        const msg = error.response.data.message;
        if (msg === 'Access Denied') {
            toast.error('Sua sessão expirou.');
            window.location.href = '/logout';
            return;
        }
    }
    return Promise.reject(error);
  });

export default apiDAM;