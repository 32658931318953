import * as React from 'react';
import { Box, Button, Container, Paper, Grid, Typography, TextField, FormControl, InputLabel, MenuItem, OutlinedInput, 
    Table, TableBody, TableCell, TableContainer, TableRow, 
    Alert} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import * as moment from 'moment';
import { toast } from 'react-toastify';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'moment/locale/pt-br';

import PageDefault from '../../components/PageDefault';
import { CurrencyMaskCustom } from '../../components/MaskedFields';
import api from '../../services/api';
import apiDAM from '../../services/apiDAM';

export default function GerarCobrancaAleatoria() {
    const [contribuinte, setContribuinte] = React.useState({});
    const [endereco, setEndereco] = React.useState('');
    const [num_doc, setNumDoc] = React.useState('');
    const [txid, setTxid] = React.useState('');
    const [tipo_imposto, setTipoImposto] = React.useState('');
    const [tipo_imposto_txid, setTipoImpostoTxid] = React.useState('');
    const [mes_ref, setMesRef] = React.useState(moment().format('MM'));
    const [ano_ref, setAnoRef] = React.useState(moment().format('YYYY'));
    const [valorImposto, setValorImposto] = React.useState('0');
    const [valorBc, setValorBc] = React.useState('0');
    const [valorJuros, setValorJuros] = React.useState('0');
    const [valorMulta, setValorMulta] = React.useState('0');
    const [dataVencimento, setdataVencimento] = React.useState(moment().add(30, 'days'));
    
    const [carregando, setCarregando] = React.useState(false);

    const { contribuinteID } = useParams();

    const navigate = useNavigate();

    function handleBack() {
        navigate(-1);
    }

    function isValid() {
        if (num_doc.trim() === '') {
            toast.error('Número do Documento inválido');
            return false;
        }
        if (txid.trim() === '') {
            toast.error('TXID inválido');
            return false;
        }
        if (mes_ref.trim() === '') {
            toast.error('Mês de Referência inválido');
            return false;
        }
        if (ano_ref.trim() === '') {
            toast.error('Ano de Referência inválido');
            return false;
        }
        if (tipo_imposto.trim() === '') {
            toast.error('Tipo de Cobrança inválida');
            return false;
        }
        if (valorImposto.trim() === '' || valorImposto.trim() === '0') {
            toast.error('Valor inválido');
            return false;
        }
        if (valorBc.trim() === '') {
            toast.error('Valor BC inválido');
            return false;
        }
        if (valorJuros.trim() === '') {
            toast.error('Valor de Juros inválido');
            return false;
        }
        if (valorMulta.trim() === '') {
            toast.error('Valor de Multa inválido');
            return false;
        }
        if (dataVencimento == null || dataVencimento.format('YYYY-MM-DDTH:mm:ss') === 'Data inválida' || dataVencimento <= moment()) {
            toast.error('Data de vencimento inválida');
            return false;
        }
        return true;
    }

    function handleSubmit() {
        if (isValid()) {
            setCarregando(true);

            const valor_imposto = valorImposto.replace(/[^\d,]/g, '').replace(/[,]/g, '.');
            const valor_multa = valorMulta.replace(/[^\d,]/g, '').replace(/[,]/g, '.');
            const valor_juros = valorJuros.replace(/[^\d,]/g, '').replace(/[,]/g, '.');
            const valor_bc = valorBc.replace(/[^\d,]/g, '').replace(/[,]/g, '.');

            const data_vencimento = dataVencimento.format('DD/MM/YYYY');
            const cpfFormatado = contribuinte.cpfCnpj.length === 11 ? contribuinte.cpfCnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4") : contribuinte.cpfCnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
            const identificador = tipo_imposto_txid + String(txid).padStart(5, '0');

            const dataPy = {
                'cpf': cpfFormatado,
                'razaoSocial': contribuinte.nome,
                'enderecoCobranca': endereco,
                'enderecoImovel': endereco,
                "damNumero": num_doc + "/" + ano_ref,
                "numDoc": num_doc + "/" + ano_ref,
                "exercicio": ano_ref,
                "periodoReferencia": "" + mes_ref + "/" + ano_ref,
                "vencimento": data_vencimento,
                "valorApurado": Number(valor_imposto),
                "valorMulta": Number(valor_multa),
                "valorJuros": Number(valor_juros),
                "valorTotal": Number(valor_imposto) + Number(valor_multa) + Number(valor_juros),
                'identificador': identificador,
                'tipoImposto': tipo_imposto,
                'valorBC': Number(valor_bc)
            }
            
            apiDAM.post(`/cobranca?com-aviso=False`, dataPy)
            .then(res => {
                setCarregando(false);
                const blob = new Blob([res.data], {
                    type: 'application/pdf'
                });
                const url = window.URL.createObjectURL(blob);
                window.open(url);
            })
            .catch(err => {
                setCarregando(false);
                const message = err.response.data.message;
                toast.error(message);
            })
        } else {
            setCarregando(false);
        }
    }

    React.useEffect(() => {

        if (contribuinteID) {
            // recupera os dados do contribuinte
            setCarregando(true);
            api.get(`/contribuinte/${contribuinteID}`)
            .then(res => {
                setContribuinte(res.data);
                let enderecoCobranca = res.data.endereco;
                enderecoCobranca.numero = enderecoCobranca.numero === 0 ? 'S/N' : enderecoCobranca.numero;
                setEndereco(enderecoCobranca.rua + ', ' + enderecoCobranca.numero + ', ' + enderecoCobranca.bairro + ', ' + enderecoCobranca.cidade + '/' + enderecoCobranca.estado)
                setCarregando(false);
            })
            .catch(err => {
                setCarregando(false);
                if (err.response) {
                    const message = err.response.data.message;
                    toast.error(message);
                }
            });
        } else {
            navigate(-1);
        }
    }, [contribuinteID, navigate]);

    const rows = [{
        'tipo': 'Contribuinte',
        'valor': contribuinte.nome
    }, {
        'tipo': 'Endereço de Cobrança',
        'valor': endereco
    }];

    const tipo = [{
        'label': 'ISSQN (ISS) – 5%',
        'valor': 'ISS'
    }, {
        'label': 'ITIV (ITBI) - 2%',
        'valor': 'ITBI'
    }, {
        'label': 'IRPF-IRPJ',
        'valor': 'IRPF'
    }, {
        'label': 'Taxa',
        'valor': 'TAXA'
    }, {
        'label': 'Multa',
        'valor': 'MULTA'
    }]

    return (
        <PageDefault carregando={carregando} title='Geração de DAM para Contribuinte'>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableBody>
                    {rows.map((row) => (
                        <TableRow
                        key={row.tipo}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align="right">{row.tipo}</TableCell>
                            <TableCell align="right">{row.valor}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Alert severity="error" sx={{mt: 2}}>
                Lembrando que a cobrança ainda não está sendo arquivada. Apenas gerando o PDF. 
                Caso necessite de histórico, salve manualmente. TXID = TIPO + NUM_DOC (com 5 números)
            </Alert>
            <Alert severity="warning" sx={{mt: 2}}>
                O campo TXID é o identificador que aparecerá no extrato bancário, deve ser formado apenas por números
            </Alert>

            <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>

                <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                    <React.Fragment>
                        <Typography variant="h6" gutterBottom>
                            Dados da Cobrança
                        </Typography>
                        <Grid container spacing={3}>

                            <Grid item xs={12}>
                                <TextField
                                    id="ano_ref"
                                    select
                                    fullWidth
                                    required
                                    label="Tipo"
                                    value={tipo_imposto}
                                    onChange={e => {
                                        setTipoImposto(e.target.value);
                                        const a = tipo.filter(i => i.label === e.target.value);
                                        setTipoImpostoTxid(a[0].valor);
                                    }}
                                >
                                    {tipo.map(({ label }) => (
                                        <MenuItem value={label} key={label}>{label}</MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    id="numDoc"
                                    name="numDoc"
                                    label="Número do Doc."
                                    fullWidth
                                    value={num_doc}
                                    onChange={e => setNumDoc(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    id="txid"
                                    name="txid"
                                    label="TXID (Código identificador do PIX)"
                                    type={'number'}
                                    fullWidth
                                    value={txid}
                                    onChange={e => setTxid(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="mes_ref"
                                    select
                                    fullWidth
                                    required
                                    label="Mês Referência"
                                    value={mes_ref}
                                    onChange={e => setMesRef(e.target.value)}
                                >
                                    <MenuItem value='01'>01</MenuItem>
                                    <MenuItem value='02'>02</MenuItem>
                                    <MenuItem value='03'>03</MenuItem>
                                    <MenuItem value='04'>04</MenuItem>
                                    <MenuItem value='05'>05</MenuItem>
                                    <MenuItem value='06'>06</MenuItem>
                                    <MenuItem value='07'>07</MenuItem>
                                    <MenuItem value='08'>08</MenuItem>
                                    <MenuItem value='09'>09</MenuItem>
                                    <MenuItem value='10'>10</MenuItem>
                                    <MenuItem value='11'>11</MenuItem>
                                    <MenuItem value='12'>12</MenuItem>
                                </TextField>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="ano_ref"
                                    select
                                    fullWidth
                                    required
                                    label="Ano Referência"
                                    value={ano_ref}
                                    onChange={e => (
                                        setAnoRef(e.target.value)
                                    )}
                                >
                                    <MenuItem value='2022'>2022</MenuItem>
                                    <MenuItem value='2023'>2023</MenuItem>
                                    <MenuItem value='2024'>2024</MenuItem>
                                </TextField>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormControl variant="outlined" fullWidth required>
                                    <InputLabel htmlFor="valorJuros">Valor</InputLabel>
                                    <OutlinedInput
                                        label="Valor"
                                        value={valorImposto}
                                        onChange={(e) => { setValorImposto(e.target.value) }}
                                        id="valor"
                                        name="valor"
                                        inputComponent={CurrencyMaskCustom}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormControl variant="outlined" fullWidth required>
                                    <InputLabel htmlFor="valorJuros">BC</InputLabel>
                                    <OutlinedInput
                                        label="BC"
                                        value={valorBc}
                                        onChange={(e) => { setValorBc(e.target.value) }}
                                        id="valor"
                                        name="valor"
                                        inputComponent={CurrencyMaskCustom}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormControl variant="outlined" fullWidth required>
                                    <InputLabel htmlFor="valorMulta">Valor Multa</InputLabel>
                                    <OutlinedInput
                                        label="Valor Multa"
                                        value={valorMulta}
                                        onChange={(e) => { setValorMulta(e.target.value) }}
                                        id="valorMulta"
                                        name="valorMulta"
                                        inputComponent={CurrencyMaskCustom}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl variant="outlined" fullWidth required>
                                    <InputLabel htmlFor="valorJuros">Valor Juros</InputLabel>
                                    <OutlinedInput
                                        label="Valor Juros"
                                        value={valorJuros}
                                        onChange={(e) => { setValorJuros(e.target.value) }}
                                        id="valorJuros"
                                        name="valorJuros"
                                        inputComponent={CurrencyMaskCustom}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='pt-br'>
                                    <DemoContainer components={['DatePicker']}>
                                        <DatePicker 
                                            label="Data de Vencimento *"
                                            value={dataVencimento}
                                            onChange={(newValue) => setdataVencimento(newValue)}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </Grid>
                        </Grid>

                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                onClick={handleBack}
                                sx={{ mt: 3, ml: 1 }}>
                                Voltar
                            </Button>
                            <Button
                                variant="contained"
                                onClick={handleSubmit}
                                sx={{ mt: 3, ml: 1 }}
                            >
                                Gerar DAM
                            </Button>
                        </Box>
                    </React.Fragment>
                </Paper>
            </Container>
        </PageDefault>
    );
}