import * as React from 'react';
import { Box, Button, Container, Paper, TextField, Grid, MenuItem, OutlinedInput, InputLabel, FormControl, Typography, Alert } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import 'moment/locale/pt-br';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { MuiFileInput } from 'mui-file-input';
import FormLabel from '@mui/material/FormLabel';
import AttachFileIcon from '@mui/icons-material/AttachFile'
import CloseIcon from '@mui/icons-material/Close'

import PageDefault from '../../components/PageDefault';
import { CurrencyMaskCustom } from '../../components/MaskedFields';
import api from '../../services/api';
import apiDAM from '../../services/apiDAM';
import moment from 'moment';

export default function PayCharger() {
    const [tipoEntrada, setTipoEntrada] = React.useState('manual');
    const [numero, setNumero] = React.useState('');
    const [formaPagamento, setFormaPagamento] = React.useState('Depósito');
    const [valorPago, setValorPago] = React.useState('0');
    const [dataPagamento, setdataPagamento] = React.useState(null);

    const [file, setFile] = React.useState(null);
    const [resultadoFile, setResultadoFile] = React.useState('');

    const [openDialog, setOpenDialog] = React.useState(false);
    const [carregando, setCarregando] = React.useState(false);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const damNum = queryParams.get('numero');

    React.useEffect(() => {
        if (damNum) {
            setNumero(damNum);
        }
    }, [damNum]);

    React.useEffect(() => {
        apiDAM.get('/')
        .then(res => {
            console.log(res.data.Status);
        })
        .catch(err => {
            console.log('Erro ao iniciar serviço');
        })
    }, []);

    const navigate = useNavigate();

    const handleSubmit = () => {
        setOpenDialog(false);
        setCarregando(true);

        if (tipoEntrada === 'manual') {
            const valor_pago = valorPago.replace(/[^\d,]/g, '').replace(/[,]/g, '.');

            const payload = {
                'dataPagamento': dataPagamento.format('YYYY-MM-DDTHH:mm:ss') + '.000Z',
                formaPagamento,
                'id': Number(numero),
                'valorPago': Number(valor_pago)
            }
            api.put(`/cobranca/pagar`, payload)
                .then(res => {
                    setCarregando(false);
                    toast.success(`Cobrança ${res.data.id}/${res.data.exercicio} paga com sucesso`);

                    if (damNum !== null) { // se veio da pagina de cobrancas = redirect
                        navigate(-1);
                    } else { // caso contrario, mantem na pagina
                        setNumero('');
                        setValorPago('0');
                        setdataPagamento(null);
                    }
                })
                .catch(err => {
                    setCarregando(false);
                    toast.error(err.response.data.message || 'Ocorreu um erro, verifique os dados');
                })
        } else {
            let formData = new FormData();
            formData.append('file', file);
            
            apiDAM.put('/cobranca', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'keyword': process.env.REACT_APP_API_DAM_KEYWORD
                }
            })
            .then(res => {
                setCarregando(false);
                let result = '';

                res.data.forEach(item => {
                    result = result + item.id + ': ' + item.retorno + '\n';
                })
                setResultadoFile(result);
            })
            .catch(err => {
                setCarregando(false);
                toast.error(err.message || 'Verifique o arquivo e tente novamente');
            })
        }
    }

    const handleBack = () => {
        navigate(-1);
    }

    function isValid() {
        if (tipoEntrada === 'manual') {
            var reg = /^\d+$/;
            if (!(reg.test(numero)) || numero === 0) {
                toast.error('Preencha corretamente o Número da DAM');
                return false;
            }
            if (valorPago.trim() === '' || valorPago.trim() === '0') {
                toast.error('Valor pago inválido');
                return false;
            }
            if (!(['PIX', 'Depósito'].includes(formaPagamento))) {
                toast.error('Forma de pagamento inválida');
                return false;
            }
            if (dataPagamento == null || dataPagamento.format('YYYY-MM-DDTH:mm:ss') === 'Data inválida' || dataPagamento > moment()) {
                toast.error('Data de pagamento inválida');
                return false;
            }
        } else if (tipoEntrada === 'arquivo') {
            if (file === null) {
                toast.error('Selecione um arquivo');
                return false;
            }
            if (file.type !== 'application/vnd.ms-excel') {
                toast.error('Formato de arquivo inválido');
                return false;
            }
        }
        return true;
    }

    const handleOpen = e => {
        if (isValid()) {
            setOpenDialog(true);
        }
    }

    const handleClose = () => {
        setOpenDialog(false);
    }
    return (
        <PageDefault carregando={carregando} title='Baixa de Cobranças'>
            <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
                <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                    <React.Fragment>
                        <Grid container spacing={5} mt={1}>
                            <Grid item xs={12}>
                                <FormControl disabled={damNum !== null}>
                                    <FormLabel id="demo-row-radio-buttons-group-label">Tipo de entrada</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        value={tipoEntrada}
                                        onChange={(e) => {
                                            setTipoEntrada(e.target.value);
                                            setFile(null);
                                        }}
                                    >
                                        <FormControlLabel value="manual" control={<Radio />} label="Manual" />
                                        <FormControlLabel value="arquivo" control={<Radio />} label="Lote" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>

                            {tipoEntrada === 'manual' && 
                            <>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        id="numero"
                                        name="numero"
                                        label="Número"
                                        type="number"
                                        min={0}
                                        fullWidth
                                        disabled={damNum !== null}
                                        value={numero}
                                        onChange={e => setNumero(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        id="tipo"
                                        select
                                        fullWidth
                                        required
                                        label="Forma de Pagamento"
                                        value={formaPagamento}
                                        onChange={e => setFormaPagamento(e.target.value)}
                                    >
                                        <MenuItem value='Depósito'>Depósito</MenuItem>
                                        <MenuItem value='PIX'>PIX</MenuItem>
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl variant="outlined" fullWidth required>
                                        <InputLabel htmlFor="valorMulta">Valor Pago</InputLabel>
                                        <OutlinedInput
                                            label="Valor Pago"
                                            value={valorPago}
                                            onChange={(e) => { setValorPago(e.target.value) }}
                                            id="valorPago"
                                            name="valorPago"
                                            inputComponent={CurrencyMaskCustom}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='pt-br'>
                                        <DemoContainer components={['DateTimePicker']}>
                                            <DateTimePicker 
                                                label="Data de Pagamento *"
                                                value={dataPagamento}
                                                onChange={(newValue) => setdataPagamento(newValue)}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Grid>
                            </>}

                            {tipoEntrada === 'arquivo' && 
                            <>
                                <Grid item>
                                    <Typography>
                                        O extrato deve está no formato '.xls'
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <MuiFileInput 
                                        value={file} 
                                        onChange={(e) => {setFile(e)}} 
                                        placeholder="Selecione o arquivo"
                                        clearIconButtonProps={{
                                            children: <CloseIcon fontSize="small" />
                                        }}
                                        inputProps={{
                                            accept: '.xls'
                                        }} 
                                        InputProps={{
                                            startAdornment: <AttachFileIcon />
                                        }}
                                    />
                                </Grid>
                            </>}
                        </Grid>

                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                onClick={handleBack}
                                sx={{ mt: 3, ml: 1 }}>
                                Voltar
                            </Button>
                            <Button
                                variant="contained"
                                onClick={handleOpen}
                                sx={{ mt: 3, ml: 1 }}
                            >
                                Confirmar
                            </Button>
                        </Box>

                        <Dialog
                            open={openDialog}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                Confirmação de pagamento
                            </DialogTitle>
                            <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {tipoEntrada === 'manual' ? `Deseja realmente baixar a cobrança ${numero}?` : 'Deseja realmente enviar extrato?'}
                            </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                            <Button onClick={handleClose}>Não</Button>
                            <Button onClick={handleSubmit} autoFocus>
                                Sim
                            </Button>
                            </DialogActions>
                        </Dialog>
                    </React.Fragment>
                </Paper>

                {resultadoFile !== '' && 
                <Alert severity="info"  sx={{ whiteSpace: 'pre-line' }} onClose={() => { setResultadoFile(''); }}>
                    {resultadoFile}
                </Alert>}

            </Container>
        </PageDefault>
    );
}