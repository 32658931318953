import * as React from 'react';
import { Box, Button, Container, Paper, FormControl, InputLabel, Typography, TextField, Grid, OutlinedInput, FormControlLabel, Checkbox } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import PageDefault from '../../components/PageDefault';
import AddressForm from '../../components/AddressForm';
import { CnpjMaskCustom, CpfMaskCustom, PhoneMaskCustom } from '../../components/MaskedFields';
import api from '../../services/api';

const enderecoPadrao = {
    'rua': '',
    'complemento': '',
    'numero': '',
    'bairro': 'Centro',
    'cidade': 'Olivêdos',
    'estado': 'PB'
}

export default function NewContributor() {
    const [nome, setNome] = React.useState('');
    const [pessoaJu, setPessoaJu] = React.useState(false);
    const [cpf, setCPF] = React.useState('');
    const [telefone, setTelefone] = React.useState('');
    const [placa, setPlaca] = React.useState('');
    const [endereco, setEndereco] = React.useState(enderecoPadrao);
    const [carregando, setCarregando] = React.useState(false);
    const [criarImovelApos, setCriarImovelApos] = React.useState(false);

    const navigate = useNavigate();
    const { contribuinteID } = useParams();

    React.useEffect(() => {
        if (contribuinteID) {
            // modo edicao: pego os dados do contribuinte
            setCarregando(true);
            api.get(`/contribuinte/${contribuinteID}`)
                .then(res => {
                    const { cpfCnpj, nome, placaCarro, telefone, endereco } = res.data;
                    setCPF(cpfCnpj || '');
                    setNome(nome || '');
                    setPlaca(placaCarro || '');
                    setTelefone(telefone || '');
                    setEndereco(endereco || enderecoPadrao);
                    setPessoaJu(cpfCnpj.length === 14)
                    setCarregando(false);
                })
                .catch(err => {
                    setCarregando(false);
                    if (err.response) {
                        const message = err.response.data.message;
                        toast.error(message);
                    }
                });
        }
    }, [contribuinteID])

    const isValid = () => {
        if (nome.trim() === '') {
            toast.error('Nome inválido');
            return false;
        }
        if (![11, 14, 18].includes(cpf.length)) {
            toast.error('CPF inválido: ' + cpf.length);
            return false;
        }

        if (endereco.rua.trim() === '') {
            toast.error('Rua inválida');
            return false;
        }
        var reg = /^\d+$/;
        if (!reg.test(endereco.numero)) {
            toast.error('Número inválido');
            return false;
        }
        if (endereco.bairro.trim() === '') {
            toast.error('Bairro inválido');
            return false;
        }
        if (endereco.cidade.trim() === '') {
            toast.error('Cidade inválida');
            return false;
        }
        if (endereco.estado.trim() === '') {
            toast.error('Estado inválido');
            return false;
        }
        return true;
    }

    const handleSubmit = e => {
        if (isValid()) {
            setCarregando(true);
            setEndereco({ ...endereco, cidade: endereco.cidade.charAt(0).toUpperCase() + endereco.cidade.slice(1) })

            if (contribuinteID) {
                api.put(`/contribuinte/${contribuinteID}`, {
                    nome,
                    cpfCnpj: cpf.replace(/[^\d]/g, ''),
                    telefone: telefone.replace(/[^\d]/g, ''),
                    placaCarro: placa,
                    endereco: { ...endereco, cidade: endereco.cidade.charAt(0).toUpperCase() + endereco.cidade.slice(1) }
                })
                    .then(res => {
                        setCarregando(false);
                        toast.success('Contribuinte Atualizado.');
                        navigate(-1);
                    })
                    .catch(err => {
                        setCarregando(false);
                        toast.error(err.response.data.message || 'Ocorreu um erro, verifique os dados');
                    })
            } else {
                api.post('/contribuinte', {
                    nome,
                    cpfCnpj: cpf.replace(/[^\d]/g, ''),
                    telefone: telefone.replace(/[^\d]/g, ''),
                    placaCarro: placa,
                    endereco: { ...endereco, cidade: endereco.cidade.charAt(0).toUpperCase() + endereco.cidade.slice(1) }
                })
                    .then(res => {
                        setCarregando(false);
                        toast.success('Contribuinte Cadastrado.');
                        if (criarImovelApos) {
                            const idCriado = res.data.id;
                            navigate(`/contribuintes/${idCriado}/novo-imovel?from=novo-contribuinte`)
                        } else {
                            navigate(-1);
                        }
                    })
                    .catch(err => {
                        setCarregando(false);
                        toast.error(err.response.data.message || 'Ocorreu um erro, verifique os dados');
                    })
            }
        }
    }

    const handleBack = () => {
        navigate(-1);
    }

    const titulo = contribuinteID ? 'Edição de Contribuinte' : 'Cadastro de Contribuinte';

    return (
        <PageDefault carregando={carregando} title={titulo}>
            <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
                <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                    <React.Fragment>
                        <Typography variant="h6" gutterBottom>
                            Dados Pessoais
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    id="nome"
                                    name="nome"
                                    label="Nome"
                                    fullWidth
                                    value={nome}
                                    onChange={e => setNome(e.target.value)}
                                />
                            </Grid>

                            <Grid item xs={12} sx={{paddingTop: 0}}>
                                <FormControlLabel
                                    disabled={contribuinteID ? true : false}
                                    control={<Checkbox value="remember" color="primary" checked={pessoaJu} onChange={e => {
                                        setPessoaJu(e.target.checked);
                                    }} />}
                                    label="Pessoa Jurídica?"
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormControl variant="outlined" fullWidth required>
                                    <InputLabel htmlFor="cpf">{ pessoaJu ? 'CNPJ' : 'CPF' }</InputLabel>
                                    <OutlinedInput
                                        label="CPF"
                                        value={cpf}
                                        onChange={(e) => { setCPF(e.target.value) }}
                                        id="cpf"
                                        name="cpf"
                                        inputComponent={pessoaJu ? CnpjMaskCustom : CpfMaskCustom}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel htmlFor="telefone">Telefone</InputLabel>
                                    <OutlinedInput
                                        label="Telefone"
                                        value={telefone}
                                        onChange={(e) => { setTelefone(e.target.value) }}
                                        id="telefone"
                                        name="telefone"
                                        inputComponent={PhoneMaskCustom}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="placa"
                                    name="placa"
                                    label="Placa do Veículo"
                                    helperText="Caso seja propietário de veículo registrado no município"
                                    fullWidth
                                    value={placa}
                                    onChange={e => setPlaca(e.target.value.toUpperCase())}
                                />
                            </Grid>
                        </Grid>

                        <AddressForm endereco={endereco} setEndereco={setEndereco} />

                        <FormControlLabel
                            disabled={contribuinteID ? true : false}
                            control={<Checkbox value="remember" color="primary" checked={criarImovelApos} onChange={e => setCriarImovelApos(e.target.checked)} />}
                            label="Criar imóvel com esse endereço?"
                        />

                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                onClick={handleBack}
                                sx={{ mt: 3, ml: 1 }}>
                                Voltar
                            </Button>
                            <Button
                                variant="contained"
                                onClick={handleSubmit}
                                sx={{ mt: 3, ml: 1 }}
                            >
                                Confirmar
                            </Button>
                        </Box>
                    </React.Fragment>
                </Paper>
            </Container>
        </PageDefault>
    );
}