import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import {Button, ButtonGroup} from '@mui/material';
import AdbIcon from '@mui/icons-material/Adb';
import { isAuthenticated } from '../../services/auth';

const pages = [{
  label: 'Contribuintes',
  link: '/'
}, {
  label: '+ Contribuinte',
  link: '/novo-contribuinte'
}, {
  label: 'Imóveis',
  link: '/imoveis'
}, {
  label: 'Cobranças',
  link: '/cobrancas'
}, {
  label: 'Cancelar Cobrança',
  link: '/cancelar-cobranca'
}, {
  label: 'Pagar Cobrança',
  link: '/pagar-cobranca'
}, {
  label: 'Pagamentos',
  link: '/pagamentos'
}];

export default function Header() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="static" sx={{ mb: 2 }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              //letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            AEC Olivêdos
          </Typography>
          {/* inicio do responsivo menor */}
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {isAuthenticated() && <ButtonGroup
                orientation="vertical"
                aria-label="vertical outlined button group"
              >
                {pages.map(({ label, link }) => (
                  <Button
                  href={link}
                  key={label}
                  >
                    {label}
                  </Button>
                ))}
              </ButtonGroup>}
            </Menu>
          </Box>
          <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              //letterSpacing: '.1rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            AEC Olivêdos
          </Typography>
          {/* fim do responsivo menor */}

          {isAuthenticated() ? <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map(({ label, link}) => (
              <Button
                href={link}
                key={label}
                //onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {label}
              </Button>
            ))}
          </Box> : 
          <Box sx={{ flexGrow: 1 }}></Box>}

          <Box sx={{ flexGrow: 0 }}>
            {/* <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu> */}
            {isAuthenticated() ?
            <Button href="/logout" variant="contained" color="error" sx={{ my: 1, mx: 1.5 }}>
              Sair
            </Button>
            :
            <Button href="/login" variant="contained" color="success" sx={{ my: 2 }}>
              Entrar
            </Button>}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}