import * as React from 'react';
import { IMaskInput } from 'react-imask';

export const CpfMaskCustom = React.forwardRef(function CpfMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput 
            {...other}
            mask='000.000.000-00'
            inputRef={ref}
            onAccept={(value) => onChange({ target: { name: props.name, value}})}
            overwrite
            />
    );
});

export const CnpjMaskCustom = React.forwardRef(function CpfMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput 
            {...other}
            mask='00.000.000/0000-00'
            inputRef={ref}
            onAccept={(value) => onChange({ target: { name: props.name, value}})}
            overwrite
            />
    );
});

export const PhoneMaskCustom = React.forwardRef(function PhoneMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput 
            {...other}
            mask='(00) 0 0000-0000'
            inputRef={ref}
            onAccept={(value) => onChange({ target: { name: props.name, value}})}
            overwrite
            />
    );
});

export const CurrencyMaskCustom = React.forwardRef(function CurrencyMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput 
            {...other}
            mask={Number}
            thousandsSeparator=' '
            inputRef={ref}
            onAccept={(value) => onChange({ target: { name: props.name, value}})}
            overwrite
            />
    );
});