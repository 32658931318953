import React from "react";
import { Container, Typography } from '@mui/material';

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Loading from "../Loading";

export default function PageDefault({ carregando, children, title }) {

  return (
    <div>
      <Header />
      <Container component="main" maxWidth="lg">
        <Typography component="h1" variant="h4" align="center">
          {title}
        </Typography>
        <div>
          {children}
        </div>
      </Container>
      <Footer />
      {carregando && <Loading carregando />}
    </div>
  );
}